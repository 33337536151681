import { PostsByMonthChart } from "../../../../src/components/posts-by-month-chart";
import { PostsByDayChart } from "../../../../src/components/posts-by-day-chart";
import { WordsByMonthChart } from "../../../../src/components/words-by-month-chart";
import { WordCountChart } from "../../../../src/components/word-count-chart";
import { TagPercentChart } from "../../../../src/components/tag-percent-chart";
import { ReactionsByAmount } from "../../../../src/components/reactions-by-amount";
import * as React from 'react';
export default {
  PostsByMonthChart,
  PostsByDayChart,
  WordsByMonthChart,
  WordCountChart,
  TagPercentChart,
  ReactionsByAmount,
  React
};