import { Link as GatsbyLink } from 'gatsby';
import { mix } from '@theme-ui/color';
import { PostCard } from "../../../../src/components/post-card";
import { ProfileInfo } from "../../../../src/components/profile-info";
import { NewsletterForm } from "../../../../src/components/newsletter-form";
import * as React from 'react';
export default {
  GatsbyLink,
  mix,
  PostCard,
  ProfileInfo,
  NewsletterForm,
  React
};